import * as React from "react"

import ToolScreen from '../../../../components/toolScreen'


const Tool = ({ data }) => (
  <ToolScreen
    name="Adobe InDesign"
    img_name="adobe_indesign_logo.png"
    link="https://www.adobe.com/indesign/"
    description="Adobe InDesign is the best solution for professional designers looking for a solution for designing document layouts for print production."
    twitterHandleTool="InDesign(@AdobeDesign)"
  />
)

export default Tool;


